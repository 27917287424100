// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import Tooltip from 'bootstrap/js/src/tooltip';
import Dropdown from 'bootstrap/js/src/dropdown';
import Collapse from 'bootstrap/js/src/collapse';
import Alert from 'bootstrap/js/src/alert';

document.addEventListener("turbolinks:load", function() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    })
})
